'use strict';

Gri.module({
  name: 'anchor-alpha',
  ieVersion: null,
  $el: $('.anchor-alpha'),
  container: '.anchor-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let elOffset = $this.offset().top;
    // endregion

    // region run

    // endregion

    // region events
    $this.find('a').on('click', function () {
      $this.find('a').removeClass('active');
      $(this).addClass('active');
      let thisText = $(this).text();
      let elOffset = $('[data-anchor="' + thisText + '"').offset().top;
      $('html, body').animate({scrollTop: elOffset - 150}, 1000, 'swing');
    });

    $(window).on('scroll', () => {
      stickyController();
    });

    $(document).ready(() => {
      stickyController();
    });
    // endregion

    // region events functions
    function stickyController() {
      if($(window).scrollTop() >= elOffset) {
        $this.addClass('sticky');
      } else {
        $this.removeClass('sticky');
      }
    }
    // endregion

    // region functions

    // endregion

  }
});
